import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerLeads() {
    var url = ConfigAPI.baseURL + "sales-flow-leads" + Session.getToken();
    return instance.get(url);
  },
  mostrarLeads(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarLeads(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sales-flow-leads" + Session.getToken();
    return instance.post(url,params);
  },
  editarLeads(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarLeads(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarLeads(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads/filter/query" + Session.getToken();
    return instance.post(url,params);
  }, 
  setStatusLeads(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads/change/status" + Session.getToken();
    return instance.post(url,params);    
  },

  obtenerLeadsAttachments() {
    var url = ConfigAPI.baseURL + "sales-flow-leads-attachments" + Session.getToken();
    return instance.get(url);
  },
  mostrarLeadsAttachments(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-attachments/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarLeadsAttachments(data, formData) {  
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    data = JSON.parse(data)
    for (var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }
    }

    var params = formData
    var url = ConfigAPI.baseURL + "sales-flow-leads-attachments" + Session.getToken();
    return instance.post(url,params,{headers});    
  },
  editarLeadsAttachments(data, formData) {  
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    data = JSON.parse(data)
    for (var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }
    }

    var params = formData  
    var url = ConfigAPI.baseURL + "sales-flow-leads-attachments/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarLeadsAttachments(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-attachments/"+ id + Session.getToken();
    return instance.delete(url);
  },  
  

  obtenerLeadsCategories() {
    var url = ConfigAPI.baseURL + "sales-flow-leads-categories" + Session.getToken();
    return instance.get(url);
  },
  mostrarLeadsCategories(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-categories/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarLeadsCategories(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sales-flow-leads-categories" + Session.getToken();
    return instance.post(url,params);
  },
  editarLeadsCategories(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads-categories/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarLeadsCategories(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-categories/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  
  obtenerLeadsLostMotive() {
    var url = ConfigAPI.baseURL + "sales-flow-leads-lost-motives" + Session.getToken();
    return instance.get(url);
  },
  mostrarLeadsLostMotive(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-lost-motives/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarLeadsLostMotive(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sales-flow-leads-lost-motives" + Session.getToken();
    return instance.post(url,params);
  },
  editarLeadsLostMotive(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads-lost-motives/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarLeadsLostMotive(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-lost-motives/"+ id + Session.getToken();
    return instance.delete(url);
  }, 


  obtenerLeadsSources() {
    var url = ConfigAPI.baseURL + "sales-flow-leads-sources" + Session.getToken();
    return instance.get(url);
  },
  mostrarLeadsSources(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-sources/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarLeadsSources(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sales-flow-leads-sources" + Session.getToken();
    return instance.post(url,params);
  },
  editarLeadsSources(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads-sources/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarLeadsSources(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-sources/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  
  obtenerLeadsFiscalConditions() {
    var url = ConfigAPI.baseURL + "sales-flow-leads-fiscal-conditions" + Session.getToken();
    return instance.get(url);
  },
  obtenerLeadsTypesDocuments() {
    var url = ConfigAPI.baseURL + "sales-flow-leads-types-documents" + Session.getToken();
    return instance.get(url);
  },
  obtenerLeadsTypesDocumentsFiscal() {
    var url = ConfigAPI.baseURL + "sales-flow-leads-types-documents-fiscal" + Session.getToken();
    return instance.get(url);
  },
  obtenerLeadsMaritalStatus() {
    var url = ConfigAPI.baseURL + "sales-flow-leads-marital-status" + Session.getToken();
    return instance.get(url);
  },
  
  obtenerVendedores() {
    var url = ConfigAPI.baseURL + "sellers" + Session.getToken();
    return instance.get(url);
  }, 
  obtenerStaff(id) {
    var url = ConfigAPI.baseURL + "staff/" + Session.getToken();
    return instance.get(url);
  }, 
  
  enviarNotificationExternalApproval(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads/notification/external-approval/email" + Session.getToken();
    return instance.post(url,params);
  },

  createServices(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads/create/service" + Session.getToken();
    return instance.post(url,params);
  },
  unassignService(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads/unassign/service" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerContrato() {
    var url = ConfigAPI.baseURL + "sales-flow-leads-contracts" + Session.getToken();
    return instance.get(url);
  },
  mostrarContrato(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-contracts/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarContrato(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sales-flow-leads-contracts" + Session.getToken();
    return instance.post(url,params);
  },
  editarContrato(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sales-flow-leads-contracts/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarContrato(id) {
    var url = ConfigAPI.baseURL + "sales-flow-leads-contracts/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  crearContrato(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sales-flow-leads-contracts/create/pdf" + Session.getToken();
    return instance.post(url,params);
  },    
  changeFirmLeads(data, image1) {        
    var headers = {
      'Content-Type': 'multipart/form-data'
    }
    
    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {      
      if(key!="image") {
        formData.append(key, data[key]);
      }
      
      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData

    var url = ConfigAPI.baseURL + "sales-flow-leads/change/firm" + Session.getToken();
    return instance.post(url,params,{headers});    
  }
}

export default servicesAPI;
